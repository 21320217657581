<template>
  <container>
    <card-section>
      <card>
        <template #title>Thank you!</template>
        <template #icon>
          <font-awesome-icon icon="check" />
        </template>
        <p>We received your information and will get back to you as soon as possible.</p>
      </card>
    </card-section>
  </container>
</template>

<script>
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  data() {
    return {
      startingTab: 'Leads'
    }
  }
}
</script>
